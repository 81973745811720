<template>
    <div class="portfolio_edit_textarea">
<!--        <el-input type="textarea" id="portfolio" v-model="portfolio"></el-input>-->
        <quill-editor
             class="h800"
            ref="myQuillEditor"
            v-model="portfolio"
            :options="editorOption"
        />
        <div class="portfolio_edit_btn_wrap pt25">
            <button class="portfolio_edit_btn confirm" @click="save()">{{ $t('yes') }}</button>
            <button class="portfolio_edit_btn cancel" @click="$router.back()">{{ $t('cancel') }}</button>
        </div>
    </div>
</template>

<script>
import {mapState, mapGetters} from "vuex";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import quillEditorMixins from "@/mixins/quillEditorMixins";
import memberValidator from "@/mixins/validators/memberValidator";

export default {
    name: "MemberWritePortfolioLayout",
    mixins: [quillEditorMixins, memberValidator],
    components: {},
    inject: ['memberSetData'],
    provide() {
        return {}
    },
    props: {
        memberData: {default: () => {}},
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        this.initSetData();
        EventBus.$on('MemberWritePortfolioLayoutForceUpdate', this.layoutForceUpdate);
    },
    beforeDestroy() {
        EventBus.$off('MemberWritePortfolioLayoutForceUpdate');
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        parentChange(type, value) {
            this.memberSetData(type, value);
        },

        initSetData() {
            if(util.isEmpty(this.memberData)) {
                return false;
            }
            this.portfolio = this.memberData.mb_portfolio
            this.portfolio = util.htmlDecode(this.portfolio);
            this.parentChange('portfolio', this.portfolio);
        },
        save() {
            EventBus.$emit('updateMember')
        },
    },
    watch: {
        'portfolio': {
            deep: true,
            handler: function (val, oldVal) {
                this.parentChange('portfolio', this.portfolio)
            },
        }
    },
}
</script>

<style scoped>

</style>
